<template>
  <div>
    <b-card>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="MapPinIcon"
          size="20"
        />
        <h4 class="mb-0 ml-50">
          Edit Checkpoint
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Site"
              label-for="Site"
            >
              <v-select
                v-model="userDataInfo.area"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="areaOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Checkpoint Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userDataInfo.checkpointName"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="userDataInfo.description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <!-- Field: Address -->
          <!-- <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Address"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userDataInfo.address"
              />
            </b-form-group>
          </b-col> -->
           <!-- Field: Pic Name -->
          <!-- <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="PIC Name"
              label-for="pic-name"
            >
              <b-form-input
                id="pic-name"
                v-model="userDataInfo.pic_name"
              />
            </b-form-group>
          </b-col> -->
           <!-- Field: Pic Contact -->
          <!-- <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="PIC Phone"
              label-for="pic-contact"
            >
              <b-form-input
                id="pic-contact"
                type="number"
                v-model="userDataInfo.pic_contact"
              />
            </b-form-group>
          </b-col> -->
           <!-- Field: Pic Email -->
          <!-- <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="PIC Email"
              label-for="pic-email"
            >
              <b-form-input
                id="pic-email"
                type="email"
                v-model="userDataInfo.pic_email"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Status"
              label-for="Status"
            >
              <v-select
                v-model="userDataInfo.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="isSubmitted"
              @click="submitUser"
            >
              Submit
            </b-button>
            <!-- <b-button
              variant="danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Cancel
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'
import checkpointStoreModule from '@/views/apps/master-data/location/checkpointStoreModule'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BFormTextarea,
    vSelect,
  },
  setup() {
    const AREA_APP_STORE_MODULE_NAME = 'app-checkpoint'

    // Register module
    if (!store.hasModule(AREA_APP_STORE_MODULE_NAME)) store.registerModule(AREA_APP_STORE_MODULE_NAME, checkpointStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AREA_APP_STORE_MODULE_NAME)) store.unregisterModule(AREA_APP_STORE_MODULE_NAME)
    })

    // Use toast
    const toast = useToast()

    const isSubmitted = false

    const userDataInfo = ref({
      area: '',
      checkpointName: '',
      description: '',
      // address: '',
      pic_name: '',
      pic_contact: '',
      pic_email: '',
      status: '',
    })

    const statusOptions = [
      { label: 'Enabled', value: 1 },
      { label: 'Disabled', value: 0 },
    ]

    const areaOptions = ref([])

    const areaOptions1 = () => {
      store
        .dispatch('app-checkpoint/fetchArea')
        .then(response => {
          console.log('ini app rea')
          console.log(response.data.data)
          // roleOptions = response.data.data
          areaOptions.value = response.data.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching area list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    areaOptions1()
    return {
      userDataInfo,
      statusOptions,
      areaOptions,
      required,
      toast,
      isSubmitted,
    }
  },
  mounted() {
    axios
      .post(`${this.$store.state.customConfig.baseURL}/web/checkpoint/getbyid`, {
        apikey: this.$store.state.customConfig.token,
        id: this.$route.params.id,
      }, {
        headers: { 'Access-Control-Allow-Origin': '*' },
      })
      .then(responseUser => {
        console.log(responseUser)
        const response = responseUser.data.data.data
        this.userDataInfo.area = response.area_id
        this.userDataInfo.checkpointName = response.name
        this.userDataInfo.description = response.description
        this.userDataInfo.pic_name = response.pic_name
        this.userDataInfo.pic_contact = response.pic_phone
        this.userDataInfo.pic_email = response.pic_email
        this.userDataInfo.status =  Number(response.status)
      })
      .catch(error => {
        console.log(error)
        // this.$refs.loginForm.setErrors(error.response.data.error)
      })
  },
  methods: {
    submitUser() {
      this.isSubmitted = true
      axios
        .post(`${this.$store.state.customConfig.baseURL}/web/checkpoint/edit`, {
          apikey: this.$store.state.customConfig.token,
          id: this.$route.params.id,
          name: this.userDataInfo.checkpointName,
          status: `${this.userDataInfo.status}`,
          description: this.userDataInfo.description,
          pic_name: this.userDataInfo.pic_name,
          pic_phone: this.userDataInfo.pic_contact,
          pic_email: this.userDataInfo.pic_email,
          area_id: this.userDataInfo.area,
        }, {
          headers: { 'Access-Control-Allow-Origin': '*' },
        })
        .then(responseUser => {
          console.log(responseUser)
          this.$router.replace('/masterdata/checkpoint')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Edit Checkpoint submitted',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully editted a Checkpoint',
                },
              })
            })
        })
        .catch(error => {
          if (error) {
            this.isSubmitted = false
            alert("Edit Checkpoint Gagal")
          }
          // this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
